import { useDevice } from 'mycs/router/DeviceContext';
import Alert from 'mycs/shared/components/Alert/Alert';
import { useIsShareDesignOpen } from 'mycs/shared/components/App/context/IsShareDesignOpenContext';
import { isValidElement, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export enum ToastMsg {
  TryAgain = 'Something went wrong, please try again',
  ContactSupport = 'Something went wrong. Please contact our customer support for assistance.',
  RefusedPayment = 'Payment authorisation was not successful. Please try again. Alternatively, use a different payment method or card.',
  CouponNotFound = 'Coupon code not found',
  InvalidCoupon = 'Coupon code not applicable',
}

export function useToast() {
  const { hasPhoneDimensions, freezePageScroll, unfreezePageScroll } =
    useDevice();
  const { isShareDesignOpen } = useIsShareDesignOpen();

  const setNotification = useCallback(
    (
      content: ToastMsg | string | JSX.Element,
      options?: {
        autoClose?: number;
        closeOnClick?: boolean;
        className?: string;
      }
    ) => {
      const alertJSX = (
        <Alert
          text={typeof content === 'string' ? content : ''}
          // Not to scroll the page on toast drag.
          onTouchStart={() => freezePageScroll()}
          onTouchEnd={() => unfreezePageScroll()}
        >
          {isValidElement(content) && content}
        </Alert>
      );

      toast(alertJSX, {
        toastId: typeof content === 'string' ? content : '', // to prevent duplicates.
        position: hasPhoneDimensions ? 'bottom-center' : 'top-right',
        autoClose: options?.autoClose || 4000,
        icon: false,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: options?.closeOnClick || false,
        draggable: hasPhoneDimensions,
        draggableDirection: 'y',
        draggablePercent: 70,
        style: {
          padding: 0,
          minHeight: 0,
          margin: isShareDesignOpen
            ? '0'
            : hasPhoneDimensions
            ? '0 5px 10px'
            : '6px 6px 10px 0',
          cursor: 'auto',
        },
        bodyStyle: {
          padding: 0,
        },
        className: options?.className,
      });
    },
    [
      isShareDesignOpen,
      hasPhoneDimensions,
      freezePageScroll,
      unfreezePageScroll,
    ]
  );

  return {
    setNotification,
  };
}
